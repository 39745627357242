import { BarChart } from "@mui/x-charts/BarChart";
import Lottie from "react-lottie";
import "./component.css";
import Loudspeaker from "../assets/loudspeaker.json";
import { isMobile } from "../Constant/Constants";
export default function CapitalWords() {
  const chartSetting = {
    yAxis: [
      {
        label: "Percentage",
      },
    ],
    width: isMobile ? 400 : 500,
    height: 300,
  };
  const datasetWords = [
    {
      Name: "Rachel",
      PercentageOfCapWords: 2.55,
    },
    {
      Name: "Irvin",
      PercentageOfCapWords: 4.88,
    },
  ];

  const loudspeakerOptions = {
    loop: true,
    autoplay: true,
    animationData: Loudspeaker,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="centralise">
      <h4>Holler with me 😎 Who likes to use more LARGE caps?</h4>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BarChart
          dataset={datasetWords}
          xAxis={[{ scaleType: "band", dataKey: "Name" }]}
          series={[
            {
              dataKey: "PercentageOfCapWords",
              label: "Percentage of words with caps",
            },
          ]}
          {...chartSetting}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ paddingTop: "20px" }}>
          <div>Number of capped words (Irvin):5937</div>
          <div>Number of capped words (Rachel):1541</div>
          <div>Percentage of capped words (Irvin):4.875224792451901%</div>
          <div>Percentage of capped words (Rachel):2.5493829202924925%</div>
        </div>
        <Lottie
          options={loudspeakerOptions}
          height={100}
          width={isMobile ? 100 : 150}
        />
      </div>
    </div>
  );
}
