import Lottie from "react-lottie";
import LandingAnimation from "./../assets/background.json";
import Typewriter from "./Typewriter";
export default function Homepage() {
  const cuteTextOptions = {
    loop: true,
    autoplay: true,
    animationData: LandingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const introText =
    "Hey there! Welcome to chat analytics. Let's explore our texting patterns tgt! ";
  return (
    <div>
      <Lottie options={cuteTextOptions} height={300} width={300} />;
      <h1>
        <Typewriter text={introText} delay={100} />
      </h1>
    </div>
  );
}
