import { BarChart } from "@mui/x-charts/BarChart";
import ReactWordcloud from "react-wordcloud";

import "./component.css";
export default function SentimentAnalysis() {
  const words = [
    {
      text: "told",
      value: 64,
    },
    {
      text: "mistake",
      value: 11,
    },
    {
      text: "thought",
      value: 16,
    },
    {
      text: "bad",
      value: 17,
    },
  ];
  return (
    <div className="centralise">
      <h4>Work in progress! Insert sentiment analysis here hehe</h4>
      <ReactWordcloud words={words} />
    </div>
  );
}
