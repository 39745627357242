import "./App.css";
import Carousel from "./container/carousel";
function App() {
  return (
    <div>
      <h1 style={{ textAlign: "center", fontFamily: "monospace" }}>
        Analysis with Rachel
      </h1>
      <Carousel />
    </div>
  );
}

export default App;
