import { BarChart } from "@mui/x-charts/BarChart";
import Lottie from "react-lottie";
import "./component.css";
import cutetext from "./../assets/cutetext.json";
import { isMobile } from "../Constant/Constants";
export default function TotalMessageSummary() {
  const chartSetting = {
    yAxis: [
      {
        label: "Count",
      },
    ],
    width: isMobile ? 400 : 500,
    height: 300,
  };
  const datasetWords = [
    {
      Name: "Rachel",
      NumberOfWordsPerMessage: 5.34,
    },
    {
      Name: "Irvin",
      NumberOfWordsPerMessage: 8.08,
    },
  ];

  const datasetMessages = [
    {
      Name: "Rachel",
      NumberOfMessages: 11353,
    },
    {
      Name: "Irvin",
      NumberOfMessages: 15101,
    },
  ];
  const cuteTextOptions = {
    loop: true,
    autoplay: true,
    animationData: cutetext,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="centralise">
      <h4>Talkative or Nah?</h4>
      <div
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <BarChart
          dataset={datasetWords}
          xAxis={[{ scaleType: "band", dataKey: "Name" }]}
          series={[
            { dataKey: "NumberOfWordsPerMessage", label: "Words Per Message" },
          ]}
          {...chartSetting}
        />
        <BarChart
          dataset={datasetMessages}
          xAxis={[{ scaleType: "band", dataKey: "Name" }]}
          series={[
            { dataKey: "NumberOfMessages", label: "Number of Messages" },
          ]}
          {...chartSetting}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <div>Average length of each Rachel's message: 25.601250770721396</div>
          <div>Average number of word per message:5.336827270325024</div>
          <div>Number of messages by Rachel: 11353</div>
          <div>Average length of each Irvin's message: 40.4040129792729</div>
          <div>Average number of word per message:8.076683663333554</div>
          <div>Number of messages by Irvin: 15101</div>
        </div>
        <Lottie
          options={cuteTextOptions}
          height={100}
          width={isMobile ? 100 : 150}
        />
      </div>
    </div>
  );
}
