import { LineChart } from "@mui/x-charts/LineChart";
import { isMobile } from "../Constant/Constants";
export default function MessagesOverMonths() {
  const years = [
    new Date(2022, 10, 1),
    new Date(2022, 11, 1),
    new Date(2022, 12, 1),
    new Date(2023, 1, 1),
    new Date(2023, 2, 1),
    new Date(2023, 3, 1),
    new Date(2023, 4, 1),
    new Date(2023, 5, 1),
    new Date(2023, 6, 1),
    new Date(2023, 7, 1),
    new Date(2023, 8, 1),
    new Date(2023, 9, 1),
    new Date(2023, 10, 1),
    new Date(2023, 11, 1),
    new Date(2023, 12, 1),
  ];

  const personOneCount = [
    182, 1461, 1640, 1396, 1181, 894, 1194, 1406, 1642, 1222, 1341, 1038, 402,
    88, 14,
  ];

  const personTwoCount = [
    136, 1227, 1301, 1210, 970, 723, 1014, 965, 1017, 892, 948, 717, 175, 44,
    14,
  ];

  return (
    <div>
      <h4>
        Messaging Timeline. Which is the most active / least active months??
      </h4>
      <LineChart
        xAxis={[
          {
            id: "Months",
            data: years,
            scaleType: "time",
            valueFormatter: (date) =>
              date.getFullYear().toString() + "-" + date.getMonth().toString(),
          },
        ]}
        series={[
          {
            id: "PersonOne",
            label: "Number of Messages By Irvin",
            data: personOneCount,
            area: false,
            showMark: true,
          },
          {
            id: "PersonTwo",
            label: "Number of Messages By Rachel",
            data: personTwoCount,
            area: false,
            showMark: true,
          },
        ]}
        width={isMobile ? 400 : 500}
        height={400}
        margin={{ left: 70 }}
      />
    </div>
  );
}
