import { BarChart } from "@mui/x-charts/BarChart";
import Lottie from "react-lottie";
import "./component.css";
import questions from "../assets/questions.json";
import { isMobile } from "../Constant/Constants";
export default function TotalQuestionsSummary() {
  const chartSetting = {
    yAxis: [
      {
        label: "Count",
      },
    ],
    width: isMobile ? 400 : 500,
    height: 300,
  };
  const datasetWords = [
    {
      Name: "Rachel",
      NumberOfQuestionMarks: 1746,
    },
    {
      Name: "Irvin",
      NumberOfQuestionMarks: 1019,
    },
  ];

  const cuteTextOptions = {
    loop: true,
    autoplay: true,
    animationData: questions,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="centralise">
      <h4>
        Curious thoughts?? Here we can see who keeps the convo flowing with
        questions!
      </h4>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BarChart
          dataset={datasetWords}
          xAxis={[{ scaleType: "band", dataKey: "Name" }]}
          series={[
            {
              dataKey: "NumberOfQuestionMarks",
              label: "Number of Question Marks",
            },
          ]}
          {...chartSetting}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ paddingTop: "20px" }}>
          <div>Number of question marks (Irvin):1019</div>
          <div>Number of question marks (Rachel):1746</div>
          <div>Number of 4W1H (Irvin):1350</div>
          <div>Number of 4W1H (Rachel):885</div>
        </div>
        <Lottie
          options={cuteTextOptions}
          height={100}
          width={isMobile ? 100 : 150}
        />
      </div>
    </div>
  );
}
